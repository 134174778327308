@import "fonts";
@import "argument-page.scss";
@import "onboarding-page.scss";

//reset
body {
  font-family: Illuminate, sans-serif;
  margin: 0;
}

input:-webkit-autofill {
  -webkit-box-shadow: 0 0 0 1000px white inset;
}
//reset end

.AppNavigator_page__XYnrH {
  max-width: 430px;
  margin: 0 auto;
}

.container {
  display: flex;
  flex-direction: column;
  background-repeat: no-repeat;
  background-size: 100% auto;
  background-position-y: -20%;
  height: calc(100vh - 45px);
  overflow-y: auto;
  box-sizing: border-box;
  &.female {
    background-position-y: -70%;

    @media (min-width: 390px) {
      background-position-y: -80%;
    }

    @media (max-height: 780px) {
      background-position-y: -180%;
    }

    @media (min-width: 430px) {
      background-position-y: -70%;
    }
  }

  @media screen and (max-width: 430px) {
    padding-bottom: 50px;
  }

  &.argument-page,
  &.onboarding-page{
    background: #FFFFFF 0 0 / auto repeat url("./assets/watermark.png");
  }

  @media (min-width: 320px) {
    background-position-y: -80%;
  }

  @media (min-width: 390px) {
    background-position-y: -30%;
  }
}

button.gradient {
  color: #FFFFFF;
  font-size: 17px;
  font-weight: 400;
  text-transform: unset;
  width: 100%;
&._primary {
  border-radius: var(--Round-Corners-Main, 15px);
  background: radial-gradient(50% 100% at 50% 0%, #FFB78D 0%, #FF8E4D 100%);
  box-shadow: 0px 0px 12px 0px #FF8E4D;
}

  &._secondary {
    border-radius: var(--Round-Corners-Main, 15px);
    background: var(--Orange-2-Gradient, radial-gradient(50% 100% at 50% 0%, #FF7E60 0%, #FF6440 100%));
    box-shadow: 0 0 12px 0 #FF8E4D;
  }

  &._third {
    border-radius: var(--Round-Corners-Main, 15px);
    background: var(--Orange-3-Gradient, radial-gradient(50% 100% at 50% 0%, #FF7461 0%, #FF3E35 100%));
    box-shadow: 0 0 12px 0 #FF8E4D;
  }
}

.content-box {
  display: flex;
  gap: 20px;
  flex-direction: column;
  background: #FFFFFF 0 0 / auto repeat url("./assets/watermark.png");
  padding: 30px;
  border-top-right-radius: 30px;
  border-top-left-radius: 30px;
}

.btn-block {
  display: flex;
  flex-wrap: nowrap;
  margin-top: auto;
  width: 100%;
  gap: 15px;
  button {
    flex-grow: 1;
    flex-shrink: 0;
  }
}

.loading {
  display: none;
  button:focus & {
    display: block;
  }
}
