.onboarding-page {
  &__content {
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    height: 100%;
  }

  &__logo {
    display: flex;
    flex-direction: column;
    flex: 3 0;
    align-items: center;
    text-align: center;
    gap: 20px;
    padding-top: 100px;
    height: 100%;
  }

  &__greeting {
    display: flex;
    flex-direction: column;
    flex: 0 1;
    padding: 0 30px;
    gap: 8px;
  }

  &__screen-1 {
    background: url("./assets/original-bubble_2.png") no-repeat 0 0 / cover;
    background-position-y: -270px;
  }

  &__screen-2,
  &__screen-3 {
    height: 100%;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    padding: 30px;
    background: url("./assets/original-bubble_2.png") no-repeat center / cover;
  }
}
