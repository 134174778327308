.argument-page {
  .header {
    position: relative;
    padding: 30px 30px 95px 30px;
    flex-grow: 0;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 15px;
    clip-path: circle(70% at 50% 1%);
    color: #FFFFFF;
    background-repeat: no-repeat;
    background-size: 100% auto;
    width: 100%;
    box-sizing: border-box;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: rgba(black, 0.3);
      z-index: -1;
    }
  }

  .content-box {
    border: none;
    background: transparent;
    padding-bottom: 70px;
  }

  .title-block {
    border-radius: 15px;
    background: radial-gradient(50% 100% at 50% 0%, #FF7E60 0%, #FF6440 100%);
    padding: 10px;
    text-align: center;
    img {
      width: 40px;
      height: 41px;
    }

    h2 {
      justify-content: center;
      padding: 8px;
      border-radius: 12px;
      background: #FFFFFF;
      box-shadow: 0 10px 15px 0 rgba(133, 98, 54, 0.25);
      margin-top: 5px;
      font-weight: 600;
    }

    p {
      margin-top: 10px;
    }
  }

  .desc {
    display: flex;
    padding: 15px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 10px;
    align-self: stretch;
    border-radius: 15px;
    box-shadow: 0 10px 15px 0 rgba(133, 98, 54, 0.25);
    &._positive {
      border-top: 15px solid #79F14F;
    }

    &._negative {
      border-top: 15px solid #FF3E3E;
    }
  }

  &._main {
    .header {
      background-image: url("./assets/IMG_8882.jpg");
      background-position: 0 100%;
    }
  }

  &._valid {
    .header {
      background-image: url("./assets/IMG_8755.jpg");
      background-position: 0 50%;
    }
  }

  &._applied {
    .header {
      padding: 30px 30px 95px 30px;
      background-image: url("./assets/IMG_8881.jpg");
      background-position: 90% 50%;
      background-size: 170%;
      clip-path: circle(80% at 50% 1%);
      @media screen and (min-width: 390px) {
        padding-bottom: 120px;
      }
    }

    .content-box {
      padding-top: 15px;
    }
  }
}
