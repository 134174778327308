@font-face {
  font-family: 'Illuminate';
  src: url("./fonts/Illuminate/Illuminate-Regular.ttf") format('ttf'),
  url("./fonts/Illuminate/Illuminate-Regular.woff") format('woff'),
  url("./fonts/Illuminate/Illuminate-Regular.woff2") format('woff2');
}

@font-face {
  font-family: 'Illuminate-Medium';
  src: url("./fonts/Illuminate/Illuminate-Medium.ttf") format('ttf'),
  url("./fonts/Illuminate/Illuminate-Medium.woff") format('woff'),
  url("./fonts/Illuminate/Illuminate-Medium.woff2") format('woff2');
}

@font-face {
  font-family: 'Illuminate-Light';
  src: url("./fonts/Illuminate/Illuminate-Light.ttf") format('ttf'),
  url("./fonts/Illuminate/Illuminate-Light.woff") format('woff'),
  url("./fonts/Illuminate/Illuminate-Light.woff2") format('woff2');
}
